import { Button } from "@ariakit/react";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { wrap } from "popmotion";
import React from "react";
import { cn } from "~/util/css";
import {
  imageUrlRegex,
  showVidControlsRegex,
  videoUrlRegex,
} from "~/util/sanitize";
import { VideoPlayer } from "./video-player";

interface MediaCarouselItemProps {
  mediaUrls: string[];
  onClick?: () => void;
}

const spotifyRegex =
  /^https:\/\/open\.spotify\.com\/embed\/episode\/[a-zA-Z0-9]+/;

function MediaBg(props: { children?: React.ReactNode }) {
  return (
    <div className="w-full h-full relative bg-black">
      <div className="absolute top-0 left-0 w-full h-full">
        {props.children}
      </div>
    </div>
  );
}

export function MediaCarouselItem(props: MediaCarouselItemProps) {
  const { mediaUrls, onClick } = props;
  const isSpotify = mediaUrls?.some((url) => spotifyRegex.test(url));
  return (
    <div
      className={`flex h-full w-full ${
        isSpotify ? "lg:flex-row flex-col lg:pb-[0px] pb-[50px]" : ""
      }`}
    >
      {mediaUrls.map((url) => {
        if (imageUrlRegex.test(url)) {
          return (
            <MediaBg key={url}>
              <div
                style={{
                  backgroundImage: `url(${url})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
                className="h-full w-full bg-center flex-1"
                onClick={onClick}
              />
            </MediaBg>
          );
        }
        if (spotifyRegex.test(url)) {
          return (
            <div className="flex-1 w-full lg:h-[100%] h-[70px] lg:m-2">
              <iframe
                key={url}
                src={url}
                className="w-full lg:h-[100%] h-80px"
                frameBorder="0"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                title="Spotify"
              />
            </div>
          );
        }
        if (videoUrlRegex.test(url)) {
          return (
            <VideoPlayer
              key={url}
              url={url}
              onClick={onClick}
              showControls={showVidControlsRegex.test(url)}
            />
          );
        }
        return null;
      })}
    </div>
  );
}

interface MediaCarouselProps {
  mediaUrls: string[];
  currentIndex?: number;
  className?: string;
  onClick?: (index: number) => void;
  itemsPerPage?: number;
  hideArrowNav?: boolean;
}

const swipeConfidenceThreshold = 10000;

const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export function MediaCarousel(props: MediaCarouselProps) {
  const initialValue = props.currentIndex ? [props.currentIndex, 0] : [0, 0];
  const itemsPerPage = props.itemsPerPage ? props.itemsPerPage : 1;
  const [[page, direction], setPage] = React.useState(initialValue);

  const slideIndex = wrap(0, props.mediaUrls.length, page * itemsPerPage);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <div
      className={cn(
        "flex flex-col relative overflow-hidden aspect-square w-full sm:max-h-[600px] group bg-black",
        props.className,
      )}
    >
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          className="absolute top-0 left-0 w-full h-full"
          key={page}
          custom={direction}
          variants={{
            enter: (direction: number) => {
              return {
                x: direction > 0 ? 1000 : -1000,
                opacity: 0,
              };
            },
            center: {
              x: 0,
              opacity: 1,
            },
            exit: (direction: number) => {
              return {
                x: direction < 0 ? 1000 : -1000,
                opacity: 0,
              };
            },
          }}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag={props.mediaUrls.length > 1 ? "x" : false}
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);
            if (
              swipe < -swipeConfidenceThreshold &&
              slideIndex < props.mediaUrls.length - 1
            ) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold && slideIndex !== 0) {
              paginate(-1);
            }
          }}
        >
          <MediaCarouselItem
            mediaUrls={props.mediaUrls.slice(
              slideIndex,
              slideIndex + itemsPerPage,
            )}
            onClick={() =>
              props.onClick ? props.onClick(slideIndex) : undefined
            }
          />
        </motion.div>
      </AnimatePresence>
      {props.mediaUrls.length > itemsPerPage && (
        <>
          {!props.hideArrowNav && slideIndex !== 0 && (
            <PrevArrow onClick={() => paginate(-1)} />
          )}

          {!props.hideArrowNav &&
            slideIndex * itemsPerPage < props.mediaUrls.length && (
              <NextArrow onClick={() => paginate(1)} />
            )}

          <Dots
            itemsCount={props.mediaUrls.length}
            itemsPerPage={itemsPerPage}
            onClick={(index) => setPage([index, index > slideIndex ? 1 : -1])}
            slideIndex={slideIndex}
          />
        </>
      )}
    </div>
  );
}

interface ArrowProps {
  onClick: () => void;
}

function PrevArrow(props: ArrowProps) {
  return (
    <Button
      className="text-white bg-black/70 hover:bg-black w-9 h-9 rounded-full items-center justify-center absolute top-[50%] left-5  group-hover:flex hidden"
      onClick={props.onClick}
    >
      <ChevronLeft className="w-7 h-7" />
    </Button>
  );
}

function NextArrow(props: ArrowProps) {
  return (
    <Button
      className="text-white bg-black/70 hover:bg-black w-9 h-9 rounded-full items-center justify-center absolute top-[50%] right-5  hidden group-hover:flex"
      onClick={props.onClick}
    >
      <ChevronRight className="w-7 h-7" />
    </Button>
  );
}

interface DotsProps {
  itemsCount: number;
  slideIndex: number;
  itemsPerPage: number;
  onClick: (index: number) => void;
}

function Dots(props: DotsProps) {
  const pageCount = Math.ceil(props.itemsCount / props.itemsPerPage);
  return (
    <div className="absolute bottom-8 left-[50%] translate-x-[-50%]">
      <ul className="flex gap-3 w-full">
        {[...Array(pageCount)].map((_, index) => (
          <li
            key={index}
            className={cn(
              "w-[9px] h-[9px] rounded-full bg-white/40 border border-black",
              index === Math.floor(props.slideIndex / props.itemsPerPage)
                ? "bg-pink-400 border-transparent"
                : "",
            )}
            onClick={() => props.onClick(index * props.itemsPerPage)}
          />
        ))}
      </ul>
    </div>
  );
}
